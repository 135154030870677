body, html {
  background-color: #F9FAFB;
  color: #4B5563;
}

.pure-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.pure-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #2563EB;
  border-radius: 50%;
  animation: pure-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #2563EB transparent transparent transparent;
}

.pure-loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.pure-loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.pure-loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes pure-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}